.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  min-width: 0;
}
@media (min-width: 900px) {
  .root {
    background: none;
  }
}
.navRoot:global(.__wab_instance) {
  background: #010101;
  width: 100%;
  height: auto;
  z-index: 50;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.navBanner:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.clickEventReporter:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.headerStripCampaign:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  background: linear-gradient(90deg, #a894ff 0%, #9400fc 46%, #3b1bff 100%) 0%
    0% / 100% 100% repeat;
  text-align: center;
  display: none;
}
@media (min-width: 900px) {
  .headerStripCampaign:global(.__wab_instance) {
    background: url("/plasmic/placeit/images/imageLeftPng5.png") top 50% left 0% / auto 100% no-repeat,
      url("/plasmic/placeit/images/imageRightPng4.png") top 50% right 0% / auto 100% no-repeat,
      linear-gradient(90deg, #a894ff 0%, #9400fc 46%, #3b1bff 100%) 0% 0% / 100%
        100% repeat;
  }
}
@media (min-width: 1536px) {
  .headerStripCampaign:global(.__wab_instance) {
    display: none;
  }
}
.link__nOamb {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.text {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.link__zf0Xw {
  display: flex;
}
.countdownText:global(.__wab_instance) {
  max-width: 100%;
  display: none;
}
.navMenuBar:global(.__wab_instance) {
  max-width: 1488px;
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;
  padding-left: 12px;
  padding-right: 12px;
  height: 48px;
}
@media (min-width: 900px) {
  .navMenuBar:global(.__wab_instance) {
    height: 60px;
    padding: 0px 1.3rem 0px 2rem;
  }
}
@media (min-width: 1024px) {
  .navMenuBar:global(.__wab_instance) {
    padding-left: 2rem;
    position: relative;
    left: auto;
    top: auto;
    max-width: 100%;
  }
}
@media (min-width: 1900px) {
  .navMenuBar:global(.__wab_instance) {
    max-width: 100%;
  }
}
.navSearchBar:global(.__wab_instance) {
  height: 48px;
  display: flex;
  flex-wrap: nowrap;
  max-width: 1488px;
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
  padding: 0.25rem 0.6rem 1rem;
}
@media (min-width: 900px) {
  .navSearchBar:global(.__wab_instance) {
    height: 60px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media (min-width: 1024px) {
  .navSearchBar:global(.__wab_instance) {
    max-width: 100%;
  }
}
@media (min-width: 1900px) {
  .navSearchBar:global(.__wab_instance) {
    max-width: 100%;
  }
}
